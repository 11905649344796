body {
	background-color: #F8F8F8;
}
.cls-2{
	fill: white !important ;
}
.cls-3{
	fill: #1027ad !important;

}
.cls-5{
	fill: none !important;
}

.MuiDialogContent-root{
	padding-left: 8px !important;
	padding-right: 8px !important;
}

header h4.MuiTypography-h4 {
    color: #0C0832;
	font-family: Mono45-Headline, sans-serif;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
}

#root .greenModalStyle {
	background-color: #295949;
	color: white;
	font-family: 'halyard-display';
	height: 100vh;
}
#root .greenModalStyle header {
	background-color: #295949;
}
#root .greenModalStyle header h4.MuiTypography-h4 {
	color: white;
}


#root .blueModalStyle {
	background-color: #0A24EB;
	color: white;
	font-family: 'halyard-display';
	height: 100vh;
}
#root .blueModalStyle header {
	background-color: #0A24EB;
}
#root .blueModalStyle header h4.MuiTypography-h4 {
	color: white;
}

.MuiToolbar-regular {
    min-height: 30px;
}

.MuiAvatar-colorDefault{
	background-color: transparent !important;
}

#root > .MuiBox-root {
	max-width: 1280px;
	margin: 0 auto 0 0;
}

#root .MuiBottomNavigationAction-label {
	font-size: 1.0rem;
}

#root .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 1.0rem;
}

#root .MuiGridListTileBar-title {
	font-size: 1.2rem;
}

#root  .MuiGridListTileBar-subtitle {
	font-size: 1.0rem;
}

#root .MuiIconButton-root {
	font-size: 3em;
}

#root .MuiSvgIcon-root.addBooking {
	right: 0.1em;
    bottom: 0.1em;
    position: absolute;
    width: 0.8em;
}
#root .MuiSvgIcon-root.unreadDot {
	right: 0em;
	left: 0.6em;
    bottom: 0.2em;
    position: absolute;
    width: 0.4em;
}
#root .deviceClassSelector .MuiListItem-root {
	/*background-color: red;*/
	justify-content: center;
	border: 10px solid transparent;
	border-radius: 0.4em;
}

#root .deviceClassSelector .MuiListItemIcon-root {
	justify-content: center;
}

#root .deviceClassSelector .MuiListItem-root.Mui-selected,
#root .deviceClassSelector .MuiListItem-root.Mui-selected:hover {
	background-color: #D8DCFB;
}

#root .gridUpdatePassword .MuiGrid-container{
	margin-top: 30px !important;
	justify-content: center !important;
}

.phoneForm{
	width: 100%;
}
.phoneForm{
	background-color: #F8F8F8;
}

.phoneForm .MuiInputBase-input,
.phoneForm .MuiInputBase-input:hover{
	background-color: #F8F8F8;
}
#root .MuiGridListTileBar-root {
	background-color: #F8F8F8;
}

.MuiAppBar-root.MuiAppBar-positionSticky.MuiAppBar-colorTransparent {
	box-shadow: none;
}

#root .MuiAppBar-root {
	box-shadow: none;
	background-color: #F8F8F8;
}

.gdpr {
	z-index: 1200;
	position: fixed;
	bottom: 0;
    left: 0;
	right: 0;
	color: white;
    background: rgba(48, 46, 44, 1);
	width: 100%;

}

.gdpr .MuiFormControlLabel-label.Mui-disabled{
	color: rgba(255, 255, 255, 0.46);
}

.gdpr .MuiCheckbox-root{
	color: rgb(255, 255, 255);
}

#root .MuiBottomNavigation-root  {
	-webkit-box-shadow: 0 -1px 8px 1px rgba(204,204,204,1);
	-moz-box-shadow: 0 -1px 8px 1px rgba(204,204,204,1);
	box-shadow: 5px 5px 10px rgba(179,179,179,1);
	border-radius: 20px 20px 0 0;
	height: 5em;
}

li.MuiGridListTile-root {
	box-shadow: 0 5px 6px -2px grey;

}
h5.home,
.home .MuiCardHeader-title {
	font-size: 24px;
	font-family: Mono45-Headline, sans-serif;
	font-weight: bold;
}

.MuiCard-rounded{
	border-radius: 15px!important;
}

#root .MuiListItem-divider {
	border-bottom: 1px solid #d9dadd;
}

#root .MuiListSubheader-root {
	color: #B9BDDB;
	font-size: 0.8em;
}


.bannerCardAction.MuiCardActions-root{
	justify-content: end !important;
}
.bannerCardAction .MuiButton-label{
	color: white;
}

h5 {
	font-size: 1.5em;
	font-weight: bold;
}

#root th.fc-resource {
	border-bottom: 1.2em solid rgb(224, 224, 224) !important;
}

/*#root .fc-timegrid-axis-cushion,*/
/*#root .fc-timegrid-slot-label-cushion {*/
/*#root span.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {*/
#root .fc-direction-ltr .fc-timegrid-axis-frame, .fc-direction-ltr .fc-timegrid-slot-label-frame {
    color: #0A24EB;
	font-family: Mono45-Headline, sans-serif;
	/* In case you are changing this font size make sure to check if the
       calendar time and the rows are still aligned */
    font-size: 0.9em;
	padding:0;
}

#root .fc-col-header-cell-cushion {
    color: black;
	font-family: Mono45-Headline, sans-serif;
    font-size: 0.8em;
}

.fc-theme-standard .fc-scrollgrid ,
.fc-direction-ltr .fc-scrollgrid tr > * ,
#root .fc-direction-ltr .fc-scrollgrid tr > .fc-timegrid-slot-lane {
	border-style: dashed;
	border-color: #D7E3F1;
	background-color: transparent;
}

#root .fc-direction-ltr .fc-scrollgrid tr > .fc-timegrid-slot-lane.fc-timegrid-slot-minor,
#root .fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor {
	border-color: transparent;
}

.fc .fc-timegrid-col.fc-day-today{
	background-color: transparent;
}

#root .passwordShowBox {
	border-style: solid;
	border-color: #FFFFFF;
	border-radius: 10px;
	font-family: Monospace, monospace;
	font-weight: 700;
	font-size: 28px;
	letter-spacing: 0;
	line-height: 0;
}

.passwordTitle{
	font-size: 35px;
	line-height: 0.667;
	margin-bottom: 6px;
}

.passwordTitleBlack{
	padding-left: 0!important;
	color:black;

}

.passwordDialog .MuiBox-root {
	padding: 5px 5px 5px;
}

.passwordDialog .MuiToolbar-regular {
	justify-content: end;
	display: grid;
}

.passwordDialog .MuiBox-root-24 {
	padding: 8px;


}

.passwordUsername .MuiTypography-subtitle2{
	margin-bottom: 15px;
}

.passwordInfo .MuiTypography-subtitle2{
	margin-top: 30px;
}

.headerBarDateContainer {
	font-size: 14px;
    color: #0C0832;
    font-family: halyard-display, sans-serif;
    text-transform: uppercase;
}

header .MuiIconButton-root {
	padding:0;
}

#root .MuiToolbar-regular {
	min-height: 0;
}

.room .MuiSvgIcon-root{
	font-size: 20px !important;
	color: #B9BDDB !important;
}

.room.MuiListItemIcon-root{
	margin-right: 10px;
	min-width: auto;
}

.room .MuiTypography-body2{
	color: #0C0832;
}

.room.MuiListItem-root{
	margin-bottom: -20px;
}

.regular2{
	color: #B9BDDB;
	font-family: "Halyard Display", serif;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 14px;
}
.modalHeader h4 {
	text-align: center;
}

.extendedFab .MuiFab-root.Mui-disabled {
	opacity: 0.4;
 	background-color: #FA0056;
}

.fc-timegrid-body{
	background-color: #F8F8F8;
}

.fc-timegrid-cols{
	background-color: #F8F8F8;
}

.loginTitle{
	text-align: center;
	line-height: 0.90;
}

.loginLogoContainer{
	display: grid;
	text-align: center;
}
.notificationDate{
	font-size: 16px!important;
	color: #0A24EB;
}
.notificationTitle{
	font-size: 24px!important;
	font-weight: 600!important;
}
.notificationBody{
	font-size: 24px!important;
}

.notificationReadMore{
	font-size: 24px!important;
    text-align: right;
    font-weight: 600!important;
}
.notificationsGridContainer{
	max-width: 700px;
	margin: 0 auto!important;
}

@media only screen and (max-width: 600px) {
	.loginTitle {
		text-align: left;
		line-height: 0.90;
	}
	.notificationDate{
		font-size: 10px!important;
	}
	.notificationTitle{
		font-size: 16px!important;
		font-weight: 400!important;
	}
	.notificationBody{
		font-size: 1.2rem!important;
	}
	.notificationReadMore{
		font-size: 16px!important;
	}
	.notificationsGridContainer{
		margin: -8px!important;
	}

  }


/* ----------------------------------------------
 * Generated by Animista on 2022-3-30 12:28:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------

 * This animation is used on the highlighted notification
 */
 .shake-horizontal {
	-webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0.5s both;
	        animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0.5s both;
}
 @-webkit-keyframes shake-horizontal {
	0%,
	100% {
	  -webkit-transform: translateX(0);
			  transform: translateX(0);
	}
	10%,
	30%,
	50%,
	70% {
	  -webkit-transform: translateX(-10px);
			  transform: translateX(-10px);
	}
	20%,
	40%,
	60% {
	  -webkit-transform: translateX(10px);
			  transform: translateX(10px);
	}
	80% {
	  -webkit-transform: translateX(8px);
			  transform: translateX(8px);
	}
	90% {
	  -webkit-transform: translateX(-8px);
			  transform: translateX(-8px);
	}
  }
  @keyframes shake-horizontal {
	0%,
	100% {
	  -webkit-transform: translateX(0);
			  transform: translateX(0);
	}
	10%,
	30%,
	50%,
	70% {
	  -webkit-transform: translateX(-10px);
			  transform: translateX(-10px);
	}
	20%,
	40%,
	60% {
	  -webkit-transform: translateX(10px);
			  transform: translateX(10px);
	}
	80% {
	  -webkit-transform: translateX(8px);
			  transform: translateX(8px);
	}
	90% {
	  -webkit-transform: translateX(-8px);
			  transform: translateX(-8px);
	}
  }
